<template>
  <div class="login-view">
    <div class="login-wrap">
      <div class="login-img"><img src="../assets/images/img-login.jpg"></div>
      <div class="login-con">
        <div class="login-box">
          <lay-form :model="loginModel" ref="loginForm" :rules="rules" name="userform">
            <div class="form-subtitle">Log in to</div>
            <div class="form-title"><router-link to="/">MyNewsScan</router-link></div>
            <lay-form-item class="form-item" prop="username">
              <label class="form-label">Username</label>
              <lay-input type="text" placeholder="5+ characters" v-model="loginModel.username" class="form-input icon-username">
                <template #prefix>
                  <font-awesome-icon icon="fa-regular fa-circle-user" style="font-weight: 900; font-size: 25px; color: #090970"/>
                </template>
              </lay-input>
            </lay-form-item>
            <lay-form-item class="form-item" prop="password">
              <label class="form-label">Password</label>
              <lay-input v-model="loginModel.password" :type="showPass" class="form-input icon-password">
                <template #prefix>
                  <PasswordIcon style="font-weight: 900; font-size: 25px; color: #090970"/>
                </template>
                <template #suffix >
                  <div v-show="filled" class='show-hide' @click="showHide">
                    <ShowIcon v-if="isShow" style="font-weight: 900; font-size: 25px; color: #090970"/>
                    <HideIcon v-else style="font-weight: 900; font-size: 25px; color: #090970"/>
                  </div>
                </template>
              </lay-input>
            </lay-form-item>
            <div class="form-text">
              <!-- <label class="fl"><input type="checkbox" name="agreement" class="form-checkbox" v-model="rememberMe"> Remember me</label>--> <router-link to="/forgot-password" class="fr">Forget Password?</router-link> 
  
            </div>
            <lay-form-item class="form-item m70">
              <lay-button class="form-btn" @click="submit">Login</lay-button>
              <div class="form-tips">Don’t have an account? <router-link to="/register">Register</router-link></div>
            </lay-form-item>
          </lay-form>
        </div>
        <div class="close-mark">
          <router-link to="/">
            <font-awesome-icon icon="xmark" style="font-size: 40px; font-weight: 50; position:absolute; right: 20px; top: 10px; color: #090970;"/>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PasswordIcon, ShowIcon, HideIcon } from "@layui/icons-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { reactive, ref, watch, onMounted } from 'vue';
import { layer } from "@layui/layui-vue";
import { post } from '@/api/index';
import { useRouter } from "vue-router";
import { useStore } from 'vuex';

export default {
  name: "SignRegister",
  components: {
    FontAwesomeIcon,
    PasswordIcon,
    ShowIcon,
    HideIcon
  },
  setup() {
    const loginForm = ref();
    const loginModel = reactive({
      username: "",
      password: ""
    });
    const rememberMe = ref(false);
    const router = useRouter();
    const store = useStore();
    const validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the username'));
      } else {
        if (value.length < 5) {
          callback(new Error('Please input no less than 5 characters'));
        } else {
          return true;
        }
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'));
      } else {
        return true;
      }
    };
    const rules = reactive({
      username: { validator: validateName, trigger: 'blur' },
      password: { validator: validatePassword, trigger: 'blur' }
    });
    const showPass = ref("password");
    const isShow = ref(true);
    const filled = ref(false);

    function showHide() {
      let tag = !isShow.value;
      if (tag == true) {
        showPass.value = "password";
      } else {
        showPass.value = "text";
      }
      isShow.value = tag;
    }

    function submit() {
      loginForm.value.validate((isValidate) => {
        if (!isValidate) {
          return false;
        } else {
          post(`/login/check`, loginModel).then(res => {
            console.log("res check in:" + res.res)
            if (res.res === -1) {
              layer.load(0, { time: 1500 });
              setTimeout(() => {
                layer.msg("Invalid username or password, try again...", { icon: 2, time: 1500 });
              }, 1500);
            } else {
              layer.load(0, { time: 1500 });
              sessionStorage.setItem("token", res.token)
              // console.log('set token' + res.token)
              store.commit('changeLogin', { Authorization: res.token });
              setTimeout(() => {
                layer.msg("Welcome back!", { icon: 1, time: 1500 });
                if (rememberMe.value) {
                  sessionStorage.setItem("rememberMe", loginModel.username);
                } else {
                  sessionStorage.removeItem("rememberMe");
                }
                if (sessionStorage.getItem("redirect")) {
                  router.push("/");
                  sessionStorage.removeItem("redirect");
                } else {
                  router.push("/");
                }
              }, 1500);
            }
          });
        }
      });
    }

    watch(() => loginModel.password, (to) => {
      if (to == "") {
        filled.value = false;
      } else {
        filled.value = true;
      }
    });

    onMounted(() => {
      const rememberedUsername = sessionStorage.getItem("rememberMe");
      if (rememberedUsername) {
        loginModel.username = rememberedUsername;
        rememberMe.value = true;
      }
    });

    return {
      loginForm,
      loginModel,
      showPass,
      isShow,
      showHide,
      filled,
      submit,
      rules,
      rememberMe
    }
  }
}
</script>

<style scoped>
/* 保持原样的样式 */
.login-view {
  height: 100vh;
  background-color: #e5e5e5;
}
.login-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 1400px;
  min-height: 775px;
  padding: 0 0;
  overflow: hidden;
  border-radius: 15px;
  background-color: transparent;
}
.login-img {
  display: flex;
  flex: 0 0 527px;
  width: 527px;
  height: 775px;
  justify-content: center;
  align-items: center;
  background-color: #ccd7ed;
}
.login-img img {
  display: block;
  width: 100%;
  height: 100%;
}
.login-con {
  /*border: 1px solid;*/
  flex: 0 0 913px;
  width: 913px;
  padding-left: 170px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
}
.login-box {
  display: block;
  width: 505px;
}
.form-subtitle {
  font-size: 32px;
}
.form-title {
  padding-top: 15px;
  font-size: 32px;
  color: #5473FF;
  padding-bottom: 40px;
  font-family: "Poppins-Black";
}
.form-title a {
  color: #5473FF;
}
.form-title a:hover {
  color: #2473FF;
}
.form-title.mb5 {
  padding-bottom: 5px;
}
.form-item {
  display: block;
  margin-top: 38px;
  position: relative;
}
.form-item.m70 {
  margin-top: 70px;
}
.form-item.m20 {
  margin-top: 18px;
}
.form-label {
  display: block;
  font-size: 18px;
  font-family: "Poppins-Bold";
  margin-bottom: 18px;
}
.form-item.m20 .form-label {
  margin-bottom: 10px;
}
.form-input {
  width: 100%;
  height: 50px;
  font-size: 15px;
  padding: 0 16px;
  background-color: #F2F2F2;
  border: none;
  border-radius: 15px;
}
.form-input:hover, .form-input:focus {
  background-color: #EAEAEA;
}
.show-hide:hover {
  cursor: pointer;
}
.form-text {
  display: flex;
  padding: 10px 20px 20px 12px;
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
  /*border: 1px solid;*/
  align-items: center;
  align-content: center;
  justify-content: space-between;
}
.form-text.mb5 {
  padding-bottom: 5px;
}
.form-text .fl {
  font-family: "Poppins-Bold";
}
.form-checkbox {
  width: 16px;
  height: 16px;
  vertical-align: -3.5px;
  margin-right: 10px;
  background-color: #CACACA;
}
.form-btn {
  display: block;
  width: 180px;
  height: 50px;
  font-size: 20px;
  color: #FFF !important;
  line-height: 50px;
  text-align: center;
  border: none;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Poppins-Black";
  background-color: #5473FF;
  box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2);
}
.form-btn:hover {
  background-color: #4261f0;
  box-shadow: 0px 2px 20px 0px rgba(59,93,245,0.5);
}
.form-tips {
  display: block;
  width: 100%;
  margin-top: 18px;
  font-size: 12px;
  text-align: center;
}
.form-tips a {
  color: #3B33B3;
}
.form-tips a:hover {
  text-decoration: underline;
}
.form-acc {
  display: flex;
  font-size: 12px;
  color: #646464;
}
.form-checkbox {
  flex: 0 0 16px;
}
.form-acc a {
  color: #3B33B3;
}
.form-acc a:hover {
  text-decoration: underline;
}
</style>
